import React, { useState, useEffect } from "react";
import { Icon, Table, Badge, Tooltip } from "antd";
import "./DashboardPatientList.scss";
import DashboardLayout from "./../DashboardLayout";
import PatientInfo from "./Components/PatientInfo";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PatientList_bookings_Action,
  PatientList_fetch_Action,
} from "../../../Redux/PatientList/PatientListActions";
import GetlocalStorage from "../../../Common/localStorageHelper/getLocalStorage";

const DashboardPatientList = () => {
  const [PatientDetails, setPatientDetails] = useState("PatientList");
  const [PatientPreview, setPatientPreview] = useState();
  const [Loader, setLoader] = useState(false);
  const [PatientViewLoader, setPatientViewLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  let getlocalStorage = GetlocalStorage();
  let Doctorid = getlocalStorage?.Doctorid;

  const handleLoader = (val) => {
    setLoader(val);
  };

  const handlePatientViewLoader = (val) => {
    setPatientViewLoader(val);
  };

  const routercall = (val) => {
    history.push(val);
  };

  const PatientListData = useSelector((state) => state.PatientListReducer.data);
  useEffect(() => {
    dispatch(
      PatientList_fetch_Action(Doctorid, routercall, handlePatientViewLoader)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RouterPatientCall = (val) => {
    if (val === "/") {
      history.push(val);
    } else {
      setPatientDetails(val);
    }
  };

  const onclick_PatientListView = (record) => {
    setPatientPreview(record);
    dispatch(
      PatientList_bookings_Action(
        Doctorid,
        record.patientId,
        RouterPatientCall,
        handleLoader
      )
    );
  };

  const formatDisplayPhoneNumber=(phoneNumberString)=> {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = '+1 ';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const columns = [
    {
      title: "Patient ID",
      dataIndex: "Patient_ID",
      key: "Patient_ID",
      render: (text, record) => (
       
          <span>
            {text}
          </span>
       
      ),
    },
    {
      title: "Patient Name",
      dataIndex: "Patient_Name",
      key: "Patient_Name",
      render: (text, record) => (
        <span>{text}</span>
      ),
    },
    // { title: "No of session", dataIndex: "No_of_session", key: "No_of_session", },
    { title: "Gender", dataIndex: "Gender", key: "Gender" },
    // {
    //   title: "Session Details",
    //   dataIndex: "Medication",
    //   key: "Medication",
    //   render: (text, record) => (
    //     <span
    //       className="TEXT1"
    //       onClick={() => onclick_PatientListView(record.Medication)}
    //     >
    //       view
    //     </span>
    //   ),
    // },
    {
      title: "Phone Number",
      dataIndex: "Contact_info",
      key: "Contact_info",
      render: (text, record) => (
          <span>{formatDisplayPhoneNumber(text)}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "Email_Info",
      key: "Email_Info",
      // render: (text, record) => (
      //   <span>
      //     {text ? (
      //       <Tooltip overlayClassName="DashboardHealthRecord" title={text}>
      //         <span>
      //           {text?.toString().slice(0, 10)}{" "}
      //           {text?.toString().length >= 11 && "..."}{" "}
      //         </span>
      //       </Tooltip>
      //     ) : (
      //       "-"
      //     )}
      //   </span>
      // ),
    },
    {
      title: "Session Details",
      dataIndex: "Medication",
      key: "Medication",
      render: (text, record) => (
        <span
          className="TEXT1"
          onClick={() => onclick_PatientListView(record.Medication)}
        >
          view
        </span>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "chat",
    //   render: (text, record) => (
    //     <img
    //       className="ChatIcon"
    //       src={require("../../../Assets/chaticon.png")}
    //       alt="icon1"
    //     />
    //   ),
    // },
  ];

  const data = [];

  for (const i of PatientListData ?? []) {
    data.push({
      Patient_ID: `PH_${i.patientName.substring(0, 4)}`,
      Patient_Name: i.patientName,
      Medication: i,
      Gender: i.gender,
      Contact_info: i.patientPhone,
      Email_Info: i.patientEmail,
    });
  }
  const fullName =getlocalStorage?.fullName;
  return (
    <DashboardLayout>
      <div className="DashboardPatientList-Body">
        {PatientDetails === "PatientList" && <h3>Patient list</h3>}
        {PatientDetails === "PatientInfo" && (
          <h3
            onClick={() => setPatientDetails("PatientList")}
            style={{ cursor: "pointer" }}
          >
            {PatientDetails === "PatientInfo" && <><Icon type="arrow-left"/> Patient list</>}
          </h3>
        )}
        <div className="DashboardPatientList-ProfileInfo">
          <h3 onClick={() => history.push("/dashboardsettings/accountinfo")}>
            {fullName}
          </h3>
          {/* <Badge offset={[-10, 8]} color={"#2d80b5"} dot>
            <Icon
              onClick={() => history.push("/dashboardsettings/notifications")}
              type="bell"
              theme="filled"
            />
          </Badge> */}
        </div>

        {PatientDetails === "PatientList" && (
          <>
            {/* <div className="DashboardPatientList-Filters">
              <Select defaultValue="Most Recently">
              <Option value="Most Recently">Most Recently</Option>
            </Select> 
              <div className="DEFAULT">
              <img src={require('../../../Assets/filter.png')} alt="icon1" />
              <p>Default</p>    
            </div>
            </div> */}
            <div className="DashboardPatientList-Body-Table">
              <Table
                columns={columns}
                dataSource={data}
                loading={Loader}
                pagination={{ pageSize: 8, size: "small" }}
              />
            </div>
          </>
        )}

        {PatientDetails === "PatientInfo" && (
          <PatientInfo
            PatientPreview={PatientPreview}
            PatientViewLoader={PatientViewLoader}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default DashboardPatientList;
