import React from "react";
import "./ViewPayment.scss";
import moment from "moment";
import { Tooltip } from 'antd';
const ViewPayment = ({ ViewPaymentInfo }) => {
  const {
    appointmentDate,
    appointmentSlot,
    bookingId,
    patientContactNumber,
    patientAddress,
    patientEmail,
    patientName,
    purposeOfVist,
    userAge,
    slotSessionTime,
    servicesType,
    isDeclained,
    servicesName

  } = ViewPaymentInfo?.appointment ?? {};
  const {
    recievedAmount,
    totalAmount,
    payerName,
    paymentStatus,
    paymentType,
    paymentDate,
    isAccepted,
    onlinePayment,
    insuranceDetails
  } = ViewPaymentInfo?.payment ?? {};

  const formatDisplayPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = "+1 ";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  const getModeDisplay = (mode) => {
    switch (mode) {
      case "TELEHEALTH":
        return "Tele Health";
      case "HOMEVISIT":
        return "Home Visit";
      case "CLINICALVISIT":
        return "Clinic Visit";
      default:
        return "Tele health";
    }
  };

  const Pending = !isAccepted && !isDeclained;
  console.log("sssghugbjhu", ViewPaymentInfo);
  return (
    <div className="view_payment_container">
      <div className="view_payments_content">
        <h3>Payment Details</h3>
        <div className="payment_details">
          <div className="details">
            <h5>Payer name</h5>
            <span>:</span>
            <h4>{paymentType === "Online" ? patientName : payerName}</h4>
          </div>
          <div className="details">
            <h5>Payment Date</h5>
            <span>:</span>
            {/* <h4>
              {paymentType === "Cash" || paymentType === "Insurance"
                ? moment(appointmentDate).format("MMM-DD-YYYY")
                : moment(paymentDate).format("MMM-DD-YYYY")}
            </h4> */}
            <h4>{paymentStatus === "Completed" ? moment(paymentDate).format('MMM-DD-YYYY') : "_"}</h4>
          </div>
          <div className="details">
            <h5>Payment Status</h5>
            <span>:</span>
            {/* <h4>{isDeclained && paymentType === "Online" ? "Refund is in Progress" : paymentStatus}</h4> */}
            {/* <h4>{isDeclained && paymentType === !"Cash" ? "Refund is in Progress" : paymentStatus}</h4> */}
           <h4>{paymentStatus === "Completed" && isDeclained === true ? "Refunded": paymentStatus === "Completed" ? "Paid" : "Unpaid"}</h4>
          </div>
          <div className="details">
            <h5>Payment Type</h5>
            <span>:</span>
            <h4>{paymentType}</h4>
          </div>
          <div className="details">
            <h5>Total amount</h5>
            <span>:</span>
            <h4>{totalAmount}</h4>
          </div>
          <div className="details">
            <h5>Received amount</h5>
            <span>:</span>
            <h4>{paymentStatus === "Completed" ? recievedAmount : 0}</h4>
          </div>
        </div>
      </div>
      {insuranceDetails &&
        <div className="view_payments_content">
          <h3>Insurance Details</h3>
          <div className="payment_details">
            <div className="details">
              <h5>Member Id</h5>
              <span>:</span>
              <h4>{insuranceDetails?.memberId}</h4>
            </div>
            <div className="details">
              <h5>Company Name</h5>
              <span>:</span>
              <h4>{insuranceDetails?.insuranceCompanyName}</h4>
            </div>
            <div className="details">
              <h5>Company Id</h5>
              <span>:</span>
              <h4>{insuranceDetails?.insuranceCompanyId}</h4>
            </div>
            <div className="details">
              <h5>Plan</h5>
              <span>:</span>
              <h4>{insuranceDetails?.insurancePlan}</h4>
            </div>

          </div>
        </div>
      }
      <div className="view_payments_content">
        <h3>Appointment Details</h3>
        <div className="payment_details">
          <div className="details">
            <h5>Patient Name</h5>
            <span>:</span>
            <h4>{onlinePayment ? onlinePayment?.name : patientName}</h4>
          </div>
          <div className="details">
            <h5>Patient email</h5>
            <span>:</span>
            <h4 className="email_text">{patientEmail}</h4>
          </div>
          <div className="details">
            <h5>Contact Number</h5>
            <span>:</span>
            <h4>{formatDisplayPhoneNumber(patientContactNumber)}</h4>
          </div>
          <div className="details">
            <h5>Address</h5>
            <span>:</span>
            <h4>{patientAddress}</h4>
          </div>
          <div className="details">
            <h5>Appointment Date</h5>
            <span>:</span>
            <h4>{moment(appointmentDate).format("MMM-DD-YYYY")}</h4>
          </div>
          <div className="details">
            <h5>Appointment Slot</h5>
            <span>:</span>
            <h4>{appointmentSlot}</h4>
          </div>
          <div className="details">
            <h5>slot Session Time</h5>
            <span>:</span>
            <h4>
              {slotSessionTime} <span>Mins</span>
            </h4>
          </div>
          <div className="details">
            <h5>Booking Id</h5>
            <span>:</span>
            <h5>{bookingId}</h5>
          </div>
          <div className="details">
            <h5>Service Type</h5>
            <span>:</span>
            <h4>{getModeDisplay(servicesName)}</h4>
          </div>
          {/* <div className="details">
            <h5>Age</h5>
            <span>:</span>
            <h4>{userAge}</h4>
          </div> */}
          <div className="details">
            <h5>{"Purpose Of Visit"} </h5>
            <span>:</span>
            <h4>{purposeOfVist}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPayment;
