import React, { useState } from "react";
import { Avatar, Icon, Button, Upload, Spin, message } from "antd";
import S3 from "react-aws-s3";
import CustomUpload from "../../../../Common/custom-upload";
const PhotoAvatar = ({ profilePhoto, setprofileinfo, setprofilePhoto }) => {
  const [spin, setspin] = useState({
    profilePhoto: false
  });
  const [filedata, setfiledata] = useState();

  const handleupload = (info, key) => {
    setspin(true);
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: "host" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          setprofilePhoto(data.location);
          setspin(false);
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setspin(false);
    }
  };

  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  const handleGotonext = () => {
    setprofileinfo("MyInformation");
  };

  return (
    <div className="PhotoAvatar-Body">
      <h1>Photo Avatar</h1>
      {!profilePhoto && !spin.profilePhoto && (
        <>
          <div className="PhotoAvatar-Body-UploadImg">
            <CustomUpload
              setUploadstate={setprofilePhoto}
              setspin={setspin}
              accept={"image/*"}
              name="avatar"
              listType={"picture-avatar"}
              className="Image-Uploader1"
              uploadfrom={"Mdloginprocess"}
              uploadkey={"profilePhoto"}
            >
              <h2>Tap here</h2>
            </CustomUpload>
            <h3>To upload your photo</h3>
            <p>Max. 5 MB</p>
            <p>JPG, PNG or PDF formats</p>
            <p>Recommended size: 216px x 216px</p>
          </div>
          {/* <Icon
            onClick={() => setprofileinfo("MyInformation")}
            className="ForwardIcon"
            type="step-forward"
          /> */}
        </>
      )}
      {spin.profilePhoto && (
        <div>
          <Spin className="SPIN" spinning={spin.profilePhoto} />
        </div>
      )}
      {profilePhoto && !spin.profilePhoto && (
        <div className="PhotoAvatar-Body-UploadImg2">
          <Avatar src={profilePhoto} size={192} /> <br />
          <CustomUpload
            setUploadstate={setprofilePhoto}
            setspin={setspin}
            accept={"image/*"}
            name="avatar"
            listType={"picture-avatar"}
            className="Image-Uploader1"
            uploadfrom={"Mdloginprocess"}
            uploadkey={"profilePhoto"}
          >
            <Button>Change Photo</Button> <br />
          </CustomUpload>
          <p>Max. 5 MB</p>
          <p>JPG, PNG or PDF formats</p>
          <p>Recommended size: 216px x 216px</p>
          <img
            className="btnImg"
            src={require("../../../../Assets/Arrowbutton.svg")}
            alt="btnimage"
            onClick={() => handleGotonext("MyInformation")}
          />
        </div>
      )}
    </div>
  );
};

export default PhotoAvatar;
