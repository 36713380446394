import React from "react";
import "./DashboardSideContent.scss";
import { Badge, Icon, Avatar, Divider } from "antd";
import { useHistory, Link } from "react-router-dom";
import GetlocalStorage from "../../../../Common/localStorageHelper/getLocalStorage";

const DashboardSideContent = () => {
  const history = useHistory();

  const ChatList = [
    "Support ( Admin)",
    "Doctor 1",
    "Doctor 2",
    "Doctor 3",
    "Doctor 4",
    "Watts",
  ];

  let getlocalStorage = GetlocalStorage();
  const fullName = getlocalStorage?.fullName;
  const specialtiesName = getlocalStorage?.specialtiesName;
  const profilePhoto = getlocalStorage?.profilePhoto;

  return (
    <div className="Dashboard-SideContent">
      <div className="Dashboard-SideContent-DoctorInfo">
        <div className="DoctorInfo-Icons">
          {/* <Badge offset={[-10, 8]} color={"#2d80b5"} dot>
            <Icon
              onClick={() => history.push("/dashboardsettings/notifications")}
              type="bell"
              theme="filled"
            />
          </Badge> */}
        </div>

        <div className="DoctorInfo-Details">
          <Link to="/dashboardsettings/accountinfo">
            {profilePhoto &&
            profilePhoto !== "null" &&
            profilePhoto !== "undefined" ? (
              <Avatar size={114} src={profilePhoto} />
            ) : (
              <Avatar className="NoProfileImg" size={114}>
                {fullName && fullName?.replace(/[^a-zA-Z0-9]/g, "").charAt(0)}
              </Avatar>
            )}
            <h1>{fullName}</h1>
            <p>{specialtiesName}</p>
          </Link>
        </div>
      </div>

      {/* <div className="Dashboard-SideContent-Chat">
        <div className="SideContent-ChatTitle">
          <h3>
            Open Tickets <Icon type="more" />
          </h3>
          <Divider className="ChatDivider" />
          <Divider className="divider2" />
        </div> */}
      {/* {ChatList.map((item) => ( */}
      {/* <div className="SideContent-Chat-Details">
            <Avatar src={require("../../../../Assets/user.png")} size={54} /> */}
      {/* Coming Soon .... */}
      {/* <div className="ChatContent">
              <h4>{"item"}</h4>
              <p>“Ubora has been such a</p>
            </div>
            <Badge count={2} style={{ backgroundColor: "#EF6D6B" }} /> */}
      {/* </div> */}
      {/* ))} */}
      {/* </div> */}
    </div>
  );
};

export default DashboardSideContent;
