import React, { useState, useEffect } from "react";
import { Row, Col, message, Spin, Icon, Input } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./LoginPage.scss";
import header_logo from "../../../Assets/header_logo.png";
import Arrowbutton from "../../../Assets/Arrowbutton.svg";
import CustomInput from "./../../../Common/custom-input";
import { Doctor_Login_Action } from "../../../Redux/Login/LoginAction";
import { setIsMdlogged } from "../../../Common/localStorageHelper/setLocalStorage";

const initialLoginState = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const dispatch = useDispatch();
  const [Loginform, setLoginform] = useState(initialLoginState);
  const [enable, setenable] = useState(false);
  const [Loading, setLoading] = useState(false);
  let [BlurCheck, setBlurCheck] = useState({
    email: false,
    password: false,
  });
  let [errors, setErrors] = useState({});

  const history = useHistory();

  const { email, password } = Loginform;

  const LoginhandleChange = (event) => {
    setLoginform({
      ...Loginform,
      [event.target.name]: event.target.value,
    });
  };

  let validate = () => {
    let errorsData = {};
    //email can't blank
    if (!email) {
      errorsData.email = "Email can't be blank";
    }

    //email regex
    const validEmailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (email) {
      if (!validEmailRegex.test(email)) {
        errorsData.email = "Proper email address is expected";
      }
    }
    //password can't blank
    if (!password) {
      errorsData.password = "Password can't be blank";
    }
    //password regex

    const validPasswordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})/;
    if (password) {
      if (password.length < 6 || password.length > 15) {
        errorsData.password = "Password should be between 6 and 15 characters long";
      } else if (!validPasswordRegex.test(password)) {
        errorsData.password =
          "Password should have at least one uppercase letter, one lowercase letter, and one digit";
      }
    }
    setErrors(errorsData);
  };

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state

    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    if (valid === true) {
      setBlurCheck({});
    }

    return valid;
  };

  const Routercall = (AdminApproved, isProfileCreated, isProfileCompleted) => {
    if (AdminApproved) {
      if (isProfileCreated && isProfileCompleted) {
        history.push("/maindashboard");
        setIsMdlogged(true)
      } else {
        history.push("/profileinfo");
      }
    } else {
      message.warning("Need admin approval");
    }
  };

  const handleLoader = (val) => {
    console.log("VVV", val);
    setLoading(val);
  };

  const handlestatus = (
    status,
    msg,
    Admin,
    ProfileCreated,
    isProfileCompleted
  ) => {
    if (Admin && ProfileCreated && isProfileCompleted) {
      message.success(msg);
    } else if (status !== 200) {
      message.error(msg);
    }
  };
  const onLogin = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();

    if (isValid()) {
      dispatch(
        Doctor_Login_Action(Loginform, Routercall, handleLoader, handlestatus)
      );
    }
  };
  useEffect(() => {
    const listener = (event) => {
      setBlurCheck((orevShapshot) => ({
        password: true,
        email: true,
      }));
      if (isValid()) {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          console.log("Enter key was pressed. Run your function.");
          event.preventDefault();
          onLogin();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isValid, onLogin]);
  useEffect(() => {
    if (email && password) {
      setenable(true);
    } else {
      setenable(false);
    }
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Loginform]);

  const ErrorText = BlurCheck["password"] && errors.password;

  const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

  return (
    <div>
      <div className="Login-Container" style={{ opacity: Loading && "0.5" }}>
        <Row className="Login-contain">
          <Col lg={11}>
            <div className="HeaderlogoContainer">
              <img src={header_logo} alt="Logo" />
            </div>
            <div className="Login-modal-body">
              <h1 className="Login-title">Login</h1>
              <div className="Login-flelds-inputs">
                <CustomInput
                  value={Loginform.email}
                  name="email"
                  placeholder="Email Address"
                  errorText={BlurCheck["email"] && errors.email}
                  onBlur={(event) => {
                    // setBlurCheck({ ...BlurCheck, [event.target.name]: true });

                    setBlurCheck((prevSanpShot) => ({
                      ...prevSanpShot,
                      email: true,
                    }));
                    validate();
                  }}
                  onChange={LoginhandleChange}
                />
                <Input.Password
                  value={Loginform.password}
                  name="password"
                  placeholder="Password"
                  onChange={LoginhandleChange}
                  onBlur={(event) => {
                    // setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    setBlurCheck((prevSanpShot) => ({
                      ...prevSanpShot,
                      password: true,
                    }));
                    validate();
                  }}
                />
                {ErrorText && <div className="ERROR">{ErrorText}</div>}
                {!ErrorText && <p className="NO-ERROR" />}
              </div>

              <div className="Login-agree">
                {/* <Radio onClick={Remember} checked={Radiocondtion}>Remember me</Radio> */}
                <span onClick={() => history.push("/forgot-password")}>
                  Forgot password?
                </span>
              </div>
              {/* {Loginperrors && <p style={{ color: "red", paddingTop: "1rem" }}>{Loginperrors.Radiocondtion}</p>} */}
              <div className="button-next">
                <img
                  src={Arrowbutton}
                  onClick={onLogin}
                  style={enable ? { opacity: 1 } : { opacity: 0.5 }}
                  alt="Logo"
                />
              </div>
              <div className="Login-account-check">
                <span>
                  Don’t have an account?&nbsp;
                  <span
                    className="active-color"
                    onClick={() => history.push("/servicetype")}
                  >
                    Sign up
                  </span>
                </span>
              </div>
            </div>
          </Col>
          <Spin spinning={Loading} indicator={antIcon} />
          <Col className="home-image-container" lg={13} md={22} xs={24}>
            <div className="Loginpage-ImgContent">
              <h1>Welcome to Ubora Doctor's Family</h1>
              <span>
                “We need to ask some questions to ensure we provide you the best
                care”
              </span>
              <div className="Loginpage-Border">
                <div className="Loginpage-img">
                  <img
                    src={
                      "https://ubstaticimgs.s3.ap-south-1.amazonaws.com/doc.svg"
                    }
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;