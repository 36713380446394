import React, { useState, useEffect } from "react";
import { Icon, Upload, Spin, Input, message } from "antd";
import CustomInput from "./../../../../Common/custom-input";

import S3 from "react-aws-s3";
import CustomUpload from "../../../../Common/custom-upload";

const { TextArea } = Input;

const Accomplishment = ({
  Accomplishmentdata,
  setAccomplishment,
  handleCreateProfile,
  setprofileinfo,
  setLoading,
}) => {
  const [spin, setspin] = useState({
    updatefile: false,
    currentfile: false,
  });
  const [currentfile, setcurrentfile] = useState();
  const [filedata, setfiledata] = useState();
  const [AddProinput, setAddProinput] = useState();
  const [AddProtext, setAddProtext] = useState();
  const [AddAwardsinput, setAwardsinput] = useState();
  const [AddAwardstext, setAwardstext] = useState();
  const [AddCertiinput, setAddCertiinput] = useState();
  const [AddCertifile, setAddCertifile] = useState();
  const [enable, setenable] = useState(false);
  const [RemovedCertification, setRemovedCertification] = useState([]);
  const [RemovedProMembership, setRemovedProMembership] = useState([]);
  const [RemovedAwards, setRemovedAwards] = useState([]);
  const [intialCertificationInput, setintialCertificationInput] =
    useState(false);
  const [intialProMembershipInput, setintialProMembershipInput] =
    useState(false);
  const [intialAwardsInput, setintialAwardsInput] = useState(false);
  const { Certifications, ProMemberships, Awards } = Accomplishmentdata;

  let [errors, setErrors] = useState({});

  let [BlurCheck, setBlurCheck] = useState({
    AddProtext: false,
    AddAwardstext: false,
    AddCertifile: false,
  });

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  let validate = () => {
    let errorsData = {};
    if (!AddCertifile && Certifications.length === 0) {
      errorsData.AddCertifile = "Certifications can't be blank";
    }
    if (AddCertifile && Certifications.length === 0) {
      errorsData.AddCertifile = "please add Certifications in list";
    }
    // if (!AddProtext && ProMemberships.length === 0) {
    //   errorsData.AddProtext = "ProMemberships can't be blank";
    // }
    // if (AddProtext && ProMemberships.length === 0) {
    //   errorsData.AddProtext = "please add ProMemberships in list";
    // }
    // if (!AddAwardstext && Awards.length === 0) {
    //   errorsData.AddAwardstext = "Awards can't be blank";
    // }
    // if (AddAwardstext && Awards.length === 0) {
    //   errorsData.AddAwardstext = "please add Awards in list";
    // }
    setErrors(errorsData);
  };

  useEffect(validate, [Accomplishmentdata, AddCertifile]);

  const handleGotoDashboard = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    console.log("errrd.......", errors);
    if (
      isValid() &&
      RemovedCertification.length === 0 &&
      RemovedProMembership.length === 0 &&
      RemovedAwards.length === 0
    ) {
      handleCreateProfile();
    }
  };

  useEffect(() => {
    if (
      Certifications?.length > 0 &&
      ProMemberships?.length > 0 &&
      Awards?.length > 0 &&
      RemovedCertification.length === 0 &&
      RemovedProMembership.length === 0 &&
      RemovedAwards.length === 0
    ) {
      setenable(true);
    } else {
      setenable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Accomplishmentdata]);

  const handleupload = (info, key) => {
    if (key === "AddCertifile") {
      setspin({
        ...spin,
        currentfile: true,
      });
    } else {
      setspin({
        ...spin,
        updatefile: true,
      });
    }
    setcurrentfile(key);
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: "host" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          if (key === "AddCertifile") {
            setAddCertifile(data.location);
            setspin({
              ...spin,
              currentfile: false,
            });
            setcurrentfile();
          } else {
            let newArr = { ...Accomplishmentdata };
            newArr.Certifications[key] = data.location;
            setAccomplishment(newArr);
            setspin({
              ...spin,
              updatefile: false,
            });
            setcurrentfile();
          }
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setspin({
        ...spin,
        currentfile: false,
      });
      setspin({
        ...spin,
        updatefile: false,
      });
    }
  };

  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  const handletextChange = (e) => {
    const { name, value } = e.target;
    if (name === "AddProtext") {
      setAddProtext(value);
    } else if (name === "AddAwardstext") setAwardstext(value);
  };

  const handleProMembershipsTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Accomplishmentdata };
    newArr.ProMemberships[i] = event.target.value;
    setAccomplishment(newArr);
    if (!newArr.ProMemberships[i]) {
      setRemovedProMembership([...RemovedProMembership, i]);
    } else {
      const filter = RemovedProMembership.filter((item) => item !== i);
      setRemovedProMembership(filter);
    }
  };

  const handleAwardsTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Accomplishmentdata };
    newArr.Awards[i] = event.target.value;
    setAccomplishment(newArr);

    if (!newArr.Awards[i]) {
      setRemovedAwards([...RemovedAwards, i]);
    } else {
      const filter = RemovedAwards.filter((item) => item !== i);
      setRemovedAwards(filter);
    }
  };

  const handleAddfile = () => {
    if (Certifications.length > 0) {
      setAddCertiinput(true);
      console.log("sssssss");
      if (AddCertifile) {
        setAddCertiinput(false);
        setAccomplishment({
          ...Accomplishmentdata,
          Certifications: [...Certifications, AddCertifile],
        });
        setAddCertifile();
        setAddCertiinput(true);
      }
    } else {
      if (AddCertifile) {
        setAccomplishment({
          ...Accomplishmentdata,
          Certifications: [...Certifications, AddCertifile],
        });
        setAddCertifile();
        setAddCertiinput(true);
      }
    }
  };

  const handleRemovedItems = (i, val) => {
    console.log("val", val);
    let newArr = { ...Accomplishmentdata };
    if (val === "ProMemberships") {
      const filter = newArr.ProMemberships.filter((item, index) => index !== i);
      setAccomplishment((prevState) => ({
        ...prevState,
        ProMemberships: filter,
      }));
      console.log("filter", filter);
      setintialProMembershipInput(true);
    } else if (val === "Awards") {
      const filter = newArr.Awards.filter((item, index) => index !== i);
      setAccomplishment((prevState) => ({
        ...prevState,
        Awards: filter,
      }));

      setintialAwardsInput(true);
    } else if (val === "Cert") {
      const filter = newArr.Certifications.filter((item, index) => index !== i);
      setAccomplishment((prevState) => ({
        ...prevState,
        Certifications: filter,
      }));

      setintialCertificationInput(true);
    }
  };

  const handleAddText = (val) => {
    if (val === "ProMemberships") {
      if (ProMemberships.length > 0) {
        console.log("sssssshello");
        setAddProinput(true);
        if (AddProtext) {
          setAddProinput(false);
          setAccomplishment({
            ...Accomplishmentdata,
            ProMemberships: [...ProMemberships, AddProtext],
          });
          setAddProtext();
          setAddProinput(true);
        }
      } else {
        if (AddProtext) {
          setAccomplishment({
            ...Accomplishmentdata,
            ProMemberships: [...ProMemberships, AddProtext],
          });
          setAddProtext();
          setAddProinput(true);
        }
      }
    } else if (val === "Awards") {
      if (Awards.length > 0) {
        setAwardsinput(true);
        if (AddAwardstext) {
          setAwardsinput(false);
          setAccomplishment({
            ...Accomplishmentdata,
            Awards: [...Awards, AddAwardstext],
          });
          setAwardstext();
          setAwardsinput(true);
        }
      } else {
        if (AddAwardstext) {
          setAccomplishment({
            ...Accomplishmentdata,
            Awards: [...Awards, AddAwardstext],
          });
          setAwardstext();
          setAwardsinput(true);
        }
      }
    }
  };
console.log("Accomplishmentdata",Accomplishmentdata)
  return (
    <div className="Accomplishment-Body">
      <h3>Accomplishments</h3>
      <div className="Accomplishment-Body-Details">
        <h4 className="NoTextarea"><span className="star">*</span>Certifications</h4>
        <h4 className="NoTextarea">:</h4>
        <div className="Textarea-Details">
          {Certifications.length > 0
            ? Certifications.map((item, i) => (
                <div className="Inner-Textarea-Details">
                  <CustomInput
                    className="InputCustom"
                    placeholder="Type or Upload"
                    value={item.substring(item.lastIndexOf("/") + 1)}
                    suffix={
                      !spin.updatefile || currentfile !== i ? (
                        <CustomUpload
                          setUploadstate={setAccomplishment}
                          setspin={setspin}
                          accept={
                            ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          }
                          uploadfrom={"MdLoginProcess"}
                          uploadkey={i}
                          setcurrentfile={setcurrentfile}
                          Dynamic
                          setAddCertifile={setAddCertifile}
                          uploaddata={Accomplishmentdata}
                          // email={email}
                        />
                      ) : (
                        currentfile === i && (
                          <Spin
                            className="SpinContent"
                            spinning={spin.updatefile}
                          />
                        )
                      )
                    }
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "Cert")}
                  />
                  {/* <p className="ERROR1">Field cant be blank</p> */}
                </div>
              ))
            : !intialCertificationInput && (
                <CustomInput
                  className="InputCustom"
                  placeholder="Type or Upload"
                  value={AddCertifile?.substring(
                    AddCertifile?.lastIndexOf("/") + 1
                  )}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, AddCertifile: true });
                    validate();
                  }}
                  suffix={
                    !spin.currentfile ? (
                      <CustomUpload
                        setUploadstate={setAccomplishment}
                        setspin={setspin}
                        accept={
                          ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        }
                        uploadfrom={"MdLoginProcess"}
                        uploadkey={"AddCertifile"}
                        setcurrentfile={setcurrentfile}
                        Dynamic
                        setAddCertifile={setAddCertifile}
                        uploaddata={Accomplishmentdata}
                        // email={email}
                      />
                    ) : (
                      <Spin
                        className="SpinContent"
                        spinning={spin.currentfile}
                      />
                    )
                  }
                />
              )}
          {AddCertiinput && (
            <CustomInput
              className="InputCustom2"
              placeholder="Type or Upload"
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, AddCertifile: true });
                validate();
              }}
              value={AddCertifile?.substring(
                AddCertifile?.lastIndexOf("/") + 1
              )}
              suffix={
                !spin.currentfile ? (
                  <CustomUpload
                  setUploadstate={setAccomplishment}
                  setspin={setspin}
                  accept={
                    ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  }
                  uploadfrom={"MdLoginProcess"}
                  uploadkey={"AddCertifile"}
                  setcurrentfile={setcurrentfile}
                  Dynamic
                  setAddCertifile={setAddCertifile}
                  uploaddata={Accomplishmentdata}
                  // email={email}
                />
                ) : (
                  <Spin className="SpinContent" spinning={spin.currentfile} />
                )
              }
            />
          )}
        </div>
        <Icon
          className="PLUS"
          type="plus-circle"
          theme="filled"
          onClick={handleAddfile}
        />
      </div>
      {BlurCheck["AddCertifile"] && errors.AddCertifile && (
        <p className="ERROR">
          {BlurCheck["AddCertifile"] && errors.AddCertifile}
        </p>
      )}

      <div className="Accomplishment-Body-Details">
        <h4>Professional Memberships</h4>
        <h4>:</h4>
        <div className="Textarea-Details">
          {ProMemberships.length > 0
            ? ProMemberships.map((item, i) => (
                <div className="Inner-Textarea-Details">
                  <TextArea
                    maxLength="120"
                    className="InputCustom1"
                    value={item}
                    name={item}
                    onChange={(e) => handleProMembershipsTextChange(i, e)}
                    autoSize={{ minRows: 2 }}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "ProMemberships")}
                  />
                  {RemovedProMembership &&
                    RemovedProMembership.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        )
                    )}
                </div>
              ))
            : !intialProMembershipInput && (
                <TextArea
                  maxLength="120"
                  placeholder="Enter your Professional Memberships"
                  value={AddProtext}
                  name="AddProtext"
                  onChange={handletextChange}
                  autoSize={{ minRows: 2 }}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}

          {AddProinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              placeholder="Enter your Professional Memberships"
              value={AddProtext}
              name="AddProtext"
              onChange={handletextChange}
              autoSize={{ minRows: 2 }}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          className="PLUS"
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("ProMemberships")}
        />
      </div>
      {BlurCheck["AddProtext"] && errors.AddProtext && (
        <p className="ERROR">{BlurCheck["AddProtext"] && errors.AddProtext}</p>
      )}

      <div className="Accomplishment-Body-Details">
        <h4>Awards</h4>
        <h4>:</h4>
        <div className="Textarea-Details">
          {Awards.length > 0
            ? Awards.map((item, i) => (
                <div className="Inner-Textarea-Details">
                  <TextArea
                    maxLength="120"
                    className="InputCustom1"
                    value={item}
                    name={item}
                    onChange={(e) => handleAwardsTextChange(i, e)}
                    autoSize={{ minRows: 2 }}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "Awards")}
                  />
                  {RemovedAwards &&
                    RemovedAwards.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        )
                    )}
                </div>
              ))
            : !intialAwardsInput && (
                <TextArea
                  maxLength="120"
                  placeholder="Enter your Awards"
                  value={AddAwardstext}
                  name="AddAwardstext"
                  onChange={handletextChange}
                  autoSize={{ minRows: 2 }}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}

          {AddAwardsinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              placeholder="Enter your Awards"
              value={AddAwardstext}
              name="AddAwardstext"
              onChange={handletextChange}
              autoSize={{ minRows: 2 }}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          className="PLUS"
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("Awards")}
        />
      </div>
      {BlurCheck["AddAwardstext"] && errors.AddAwardstext && (
        <p className="ERROR">
          {BlurCheck["AddAwardstext"] && errors.AddAwardstext}
        </p>
      )}

      <div className="NextBTN">
        <img
          src={require("../../../../Assets/Arrowbutton.svg")}
          alt="btn1"
          onClick={handleGotoDashboard}
          style={{ opacity: enable ? 1 : 0.5 }}
          // onClick={() => history.push("/maindashboard")}
        />
      </div>
    </div>
  );
};

export default Accomplishment;
