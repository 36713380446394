import React from "react";
import { Row, Col, Icon } from "antd";
import "./DashboardSettings.scss";
import DashboardLayout from "./../DashboardLayout";
import { useHistory } from "react-router-dom";
import Reviews from "./Reviews/Reviews";

const DashboardSettings = () => {
  const history = useHistory();

  const Payouts1 = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img alt="timer" src={require("../../../Assets/global.svg")} />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Payments & Payouts &nbsp; <Icon type="right" />
        </h3>
        <h5>See your default language, currency,</h5>
        <h5>and timeZone</h5>
      </div>
    </div>
  );

  const Privacy1 = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img src={require("../../../Assets/privacy.svg")} alt="/" />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Privacy & Sharing &nbsp; <Icon type="right" />
        </h3>
        <h5>Control connected apps, What you</h5>
        <h5>share, and who see it</h5>
      </div>
    </div>
  );

  const Notifications1 = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img src={require("../../../Assets/Notification-icon.svg")} alt="/" />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Notifications &nbsp; <Icon type="right" />
        </h3>
        <h5>Choose notification preferences and</h5>
        <h5>How you want to be contacted</h5>
      </div>
    </div>
  );

  const ManagePreferences = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img src={require("../../../Assets/Payment-svg.svg")} alt="/" />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Manage Preferences &nbsp; <Icon type="right" />
        </h3>
        <h5>Review payments, payouts,coupons</h5>
        <h5>Gift cards and taxes</h5>
      </div>
    </div>
  );

  const LoginSecurity = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img src={require("../../../Assets/login-svg.svg")} alt="/" />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Login & Security &nbsp; <Icon type="right" />
        </h3>
        <h5>Update your password and secure</h5>
        <h5>your account</h5>
      </div>
    </div>
  );

  const AccountInformation = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img src={require("../../../Assets/international-icon.svg")} alt="/" />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Account Information &nbsp; <Icon type="right" />
        </h3>
        <h5>
        Account information includes personal details, contact information, and security settings, Keep this data up-to-date.
        </h5>
      </div>
    </div>
  );

  const ReviewsInformation = (
    <div className="DashboardSettings-Card">
      <div className="DashboardSettings-Card-img">
        <img src={require("../../../Assets/international-icon.svg")} alt="/" />
      </div>
      <div className="DashboardSettings-Card-info">
        <h3>
          Reviews Information &nbsp; <Icon type="right" />
        </h3>
        <h5>Reviews provide valuable feedback from users about their experiences. They help improve services and guide future customers.</h5>
      </div>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="DashboardSettings-Container">
        <div className="DashboardSettings-body">
          {/* <h3 className="DashboardSettings-Title">Settings</h3> */}
          {/* <div className="DashboardSettings-ProfileInfo">
            <Icon type="caret-down" theme="filled" /> 
            <h3>Jane Doe</h3>
            <Badge offset={[-10,8]} color={'#2d80b5'} dot>
               <Icon onClick={()=> history.push('/dashboardsettings/notifications')} type="bell" theme="filled" />
            </Badge>
          </div> */}
          <div className="DashboardSettings-Cards-Body">
            <Row className="DashboardSettings-Cards-Row">
              <Col
                lg={8}
                sm={18}
                xs={24}
                onClick={() => history.push("/dashboardsettings/accountinfo")}
              >
                {AccountInformation}
              </Col>
              <Col
                lg={8}
                xs={24}
                sm={18}
                onClick={() => history.push("/dashboardsettings/reviews")}
              >
                {ReviewsInformation}
              </Col>
              {/* <Col
                lg={8}
                xs={24}
                sm={18}
                onClick={() => history.push("/dashboardsettings/security")}
              >
                {LoginSecurity}
              </Col>
              <Col
                lg={8}
                xs={24}
                sm={18}
                onClick={() => history.push("/dashboardsettings/preferences")}
              >
                {ManagePreferences}
              </Col> */}
            </Row>

            {/* <Row className="DashboardSettings-Cards-Row">
              <Col
                lg={8}
                xs={24}
                sm={18}
                onClick={() => history.push("/dashboardsettings/notifications")}
              >
                {Notifications1}
              </Col>
              <Col
                lg={8}
                xs={24}
                sm={18}
                onClick={() => history.push("/dashboardsettings/privacy")}
              >
                {Privacy1}
              </Col>
              
            </Row> */}
          </div>
          {/* <div className="DashboardSettings-active">
            <h4>Need to deactivate your account?</h4>
            <h5>Take care of that now</h5>
          </div> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardSettings;
