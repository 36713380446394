import React, { useState, useEffect } from "react";
import { Icon, Badge, Select, Table, Tooltip, Modal, Button, message } from "antd";
import moment from "moment";
import "./DashboardPayments.scss";
import { useHistory } from "react-router-dom";
import DashboardLayout from "./../DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { PaymentsList_fetch_Action } from "../../../Redux/Payments/PaymentActions";
import Axiosinstance from "../../../config";
import ViewPayment from "./ViewPayment/ViewPayment";
import GetlocalStorage from "../../../Common/localStorageHelper/getLocalStorage";

const { Option } = Select;

const DashboardPayments = () => {
  const [Loader, setLoader] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [viewUpdateModal, setViewUpdateModal] = useState(false)
  const [ViewPaymentInfo, setViewPaymentInfo] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  let getlocalStorage = GetlocalStorage();
  let Doctorid = getlocalStorage?.Doctorid;

  useEffect(() => {
    dispatch(PaymentsList_fetch_Action(Doctorid, routercall, handleLoader));
  }, []);

  const routercall = (val) => {
    // history.push(val);
  };

  const handleLoader = (val) => {
    setLoader(val);
  };

  // const appointmentDate = useSelector((state) => state.PaymentListReducer.data);
  const [appointmentDate, setAppointmentsData] = useState([]);

  const fetchAppointments =async() =>{
    try{
      const res = await Axiosinstance(`/payments/v1.0/getCGPayment/${Doctorid}`);
      if(res?.status >= 200 && res?.status < 300){
        setAppointmentsData(res?.data?.data);
      }else{
        setAppointmentsData([])
      }
    }catch(err){
      message.info("some thing went wrong")
    }
  }

  useEffect(() => {
    fetchAppointments();
  }, []);

  const ViewPaymnetInformation = (item) => {
    console.log("paymentssData", item);
    setViewPaymentInfo(item)
    setViewPaymentModal(true)
  }

  const fullName = getlocalStorage?.fullName;
  console.log("fullNamsse", ViewPaymentInfo);

  const [initialStatus, setInitialStatus] = useState();
  const [PaymentId, setPaymentId] = useState();

  const openUpdateModal = (id)=>{
    setViewUpdateModal(true);
    setPaymentId(id)
  }

  console.log("setInitialStatus", initialStatus);

  const updatePayment = async () => {
    let data = {
      paymentStatus: initialStatus
    };
    try {
      const res = await Axiosinstance.put(`payments/v1.0/updatepaymentstatus/${PaymentId}`, data);
      if (res?.status >= 200 && res?.status < 300) {
        message.info("Payment Status updated successfully")
        setViewUpdateModal(false);
        fetchAppointments()
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "payment.patientName",
      key: "payment.patientName",
      render: (text, record) => {
        console.log("reddddcord", record);
        const { payment, appointment} = record ?? {}; 
        const { patientName, fullNameUser } = appointment ?? {}; 
       return <span>{payment?.onlinePayment ? payment?.onlinePayment?.name : patientName}</span>
      }
    },
    {
      title: "Appointment Date",
      dataIndex: "payment.created_at",
      key: "payment.created_at",
      render: (val) => moment(val).format("MMM-DD-YYYY"),
      render: (item, record) => {
        const { appointment, payment } = record ?? {};
        return (
          <span>{moment(appointment?.appointmentDate).format('MMM-DD-YYYY')}</span>
        )
      }
    },
    {
      title: "payer Name",
      dataIndex: "payment.payerName",
      key: "payment.payerName",     
      render: (record, item) => <span>{item?.payment?.paymentType === "Online" ? item?.payment?.patientName : item?.payment?.payerName}</span>
    },
    {
      title: "Payment Date",
      dataIndex: "payment.created_at",
      key: "payment.created_at",
      render: (item, record) => {
        const { appointment, payment } = record ?? {};
        return (
          <span>{payment?.paymentStatus === "Completed" ? moment(payment?.paymentDate).format('MMM-DD-YYYY') : "N/A"}</span>
        )
      }
    },
 
    {
      title: "Type",
      dataIndex: "payment.paymentType",
      key: "payment.paymentType",
      render: (Item, value) => {
        console.log("valuessss", value?.payment?.insuranceDetails, Item);
        return (
          <span>
            {value?.payment?.insuranceDetails
              ? "Insurance"
              : (!value?.payment?.paymentType || value?.payment?.paymentType === ""
                ? "Cash"
                : value?.payment?.paymentType)
            }
          </span>
        )
      }},
    {
      title: "Status",
      dataIndex: "payment.paymentStatus",
      key: "payment.paymentStatus",
      render: (text, record) => {
        const { paymentStatus, paymentType } = record?.payment ?? {};
        const { isAccepted, isDeclained } = record?.appointment ?? {};
        console.log("shdvtfds",record, isAccepted, isDeclained, paymentStatus, paymentType)
        const Pending = !isAccepted && !isDeclained;
        return (
          <span style={Pending && paymentStatus === "Completed" ? { color: "#B0779A" } : { color: "#DE960C" }}>
            {paymentStatus === "Completed" && isDeclained === true ? "Refunded": paymentStatus === "Completed" ? "Paid" : "Unpaid"}
          </span>
        )
      },
    },
    {
      title: "Details",
      dataIndex: "Details",
      render: (text, record) => {
        console.log("recordsssss", record);
        return (
          <>
            <div className="icons_info">
              <Tooltip title="Update" >
                <Icon type="dollar" onClick={() => openUpdateModal(record?._id)} />
              </Tooltip>
              <Tooltip title="View" >
                <Icon className="EyeIcon" type="eye" onClick={() => ViewPaymnetInformation(record, text)} />
              </Tooltip>
            </div>
          </>
        )
      }
    },
  ];


  return (
    <DashboardLayout>
      <div className="DashboardPayments-Body">
        <h3>Payments</h3>
        <div className="DashboardPayments-ProfileInfo">
          {/* <Icon
            style={{ visibility: "hidden" }}
            type="caret-down"
            theme="filled"
          /> */}
          <h3 onClick={() => history.push("/dashboardsettings/accountinfo")}>
            {fullName}
          </h3>
          {/* <Badge offset={[-10, 8]} color={"#2d80b5"} dot>
            <Icon
              onClick={() => history.push("/dashboardsettings/notifications")}
              type="bell"
              theme="filled"
            />
          </Badge> */}
        </div>
        <div className="DashboardPayments-Body-Table">
          <Table
            columns={columns}
            dataSource={appointmentDate}
            pagination={{ pageSize: 8, size: "small" }}
          />
        </div>
        <Modal
          className="view_payments"
          visible={viewPaymentModal}
          centered={true}
          footer={null}
          onCancel={() => setViewPaymentModal(false)}
        // closable={false}
        >
          <ViewPayment ViewPaymentInfo={ViewPaymentInfo} />
        </Modal>
        <Modal
          className="view_updateModal"
          visible={viewUpdateModal}
          centered={true}
          footer={null}
          onCancel={() => setViewUpdateModal(false)}
        >
          <div className="paymentupdate_container">
            <h3>Payment Status</h3>
            <div className="viewupdate_content">
              <div className="update_status">
                <h4>update status:</h4>
                <Select
                  placeholder="select status"
                  value={initialStatus}
                  onChange={(e) => setInitialStatus(e)}
                >
                  <Option value="Pending">Pending</Option>
                  <Option value="Completed">Completed</Option>
                </Select>
              </div>
              <div className="update_btn">
              <Button onClick={()=> updatePayment()}>Update</Button>
              </div>
            </div>
          </div>
        </Modal>

      </div>
    </DashboardLayout>
  );
};

export default DashboardPayments;
