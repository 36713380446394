import React, { useState, useEffect } from "react";
import { Input, Icon, Upload, Select, Spin, message } from "antd";
import CustomInput from "./../../../../Common/custom-input";
import S3 from "react-aws-s3";
import CustomUpload from "../../../../Common/custom-upload";

const { Option } = Select;
const { TextArea } = Input;

const MyInformation = ({
  Informationdata,
  setprofileinfo,
  setInformation,
  SpecialitiesResponse,
  setLoading,
  Loading,
}) => {

  console.log("njbhjdhvdf", SpecialitiesResponse)
  const [spin, setspin] = useState({ lincense: false });
  // const [Addinput, setAddinput] = useState();
  const [enable, setenable] = useState(false);
  const [Addtext, setAddtext] = useState();
  const [filedata, setfiledata] = useState();
  const [SpecialitiesList, setSpecialitiesList] = useState([]);
  let [errors, setErrors] = useState({});
  let [BlurCheck, setBlurCheck] = useState({
    specialities: false,
    degree: false,
    summery: false,
    profileHeader: false,
    languages: false,
    // SpecialitiesList: false,
    lincense: false,
  });

  const {
    // specialities,
    degree,
    summery,
    lincense,
    profileHeader,
    languages,
    subSpecialtiesName,
  } = Informationdata;

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  let validate = () => {
    let errorsData = {};
    if (!profileHeader) {
      errorsData.profileHeader = "Profile Header can't be blank";
    }
    //email can't blank
    if (profileHeader) {
      if (profileHeader.length < 4)
        errorsData.profileHeader = "Profile Header can't be Short";
    }
    // if (SpecialitiesList.length === 0) {
    //   errorsData.SpecialitiesList = "other Specialties can't be blank";
    // }
    if (!degree) {
      errorsData.degree = "Degree can't be blank";
    }
    if (degree) {
      if (degree.length < 4) {
        errorsData.degree = "Degree can't be Short";
      }
    }
    if (languages.length === 0) {
      errorsData.languages = "Languages can't be blank";
    }
    if (!lincense) {
      errorsData.lincense = "License can't be blank";
    }
    // if (!summery) {
    //   errorsData.summery = "Summary can't be blank";
    // }
    setErrors(errorsData);
  };
  console.log("errors", errors);

  const handleGotoEducation = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    if (isValid()) {
      setLoading(true);
      setTimeout(() => {
        setprofileinfo("Education");
        setLoading(false);
      }, 2000);
    }
  };
  useEffect(validate, [Informationdata]);

  useEffect(() => {
    if (
      profileHeader &&
      subSpecialtiesName?.length > 0 &&
      languages?.length > 0 &&
      lincense &&
      summery
    ) {
      setenable(true);
    } else {
      setenable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Informationdata]);

  const handleupload = (info, key) => {
    setspin({
      ...spin,
      [key]: true,
    });
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: "host" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          setInformation({
            ...Informationdata,
            lincense: data.location,
          });

          setspin({
            ...spin,
            [key]: false,
          });
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setspin({
        ...spin,
        [key]: false,
      });
    }
  };
  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  // const handleAddText = (val) => {
  //   setAddinput(true);
  //   if (Addtext) {
  //     setAddinput(false);
  //     setInformation({
  //       ...Informationdata,
  //       specialities: [...specialities, Addtext],
  //     });
  //     // setInternship([
  //     //   ...Internship,
  //     //   AddInternshiptext,
  //     // ]);
  //     setAddtext();
  //   }
  // };

  // const handlespecialitiesTextChange = (i, event) => {
  //   console.log("event.target.value", i, event.target.value);
  //   let newArr = { ...Informationdata };
  //   newArr.specialities[i] = event.target.value;
  //   setInformation(newArr);
  // };

  const handletextChange = (e) => {
    const { name, value } = e.target;
    if (name === "Addtext") {
      setAddtext(value);
    }
    setInformation({
      ...Informationdata,
      [name]: value,
    });
  };

  const handleSelectChange = (e) => {
    setInformation({
      ...Informationdata,
      languages: e,
    });
  };
  const handleSelectSpecialitiesChange = (val) => {
    setSpecialitiesList(val);
  };

  useEffect(() => {
    const results = SpecialitiesResponse?.filter(({ _id: id1 }) =>
      SpecialitiesList.some((item) => item === id1)
    );
    const SpecialtieNamefilter = results?.map((item) => item.name);
    const SpecialtieIdfilter = results?.map((item) => item._id);
    console.log("SpecialtieNamefilter", SpecialtieIdfilter);
    setInformation({
      ...Informationdata,
      subSpecialtiesName: SpecialtieNamefilter,
      subSpecialtiesID: SpecialtieIdfilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SpecialitiesList]);

  const antIcon = <Icon type="loading" style={{ fontSize: 120 }} spin />;
  console.log("languages", languages);

  return (
    <div className="MyInformation-Body">
      <h3>My information</h3>
      <div className="MyInformation-Body-Details">
        <h4>Other Specialities</h4>
        <h4>:</h4>
        <Select
          className="SpecialitySelect"
          placeholder="Select Other Specialities"
          mode="multiple"
          value={SpecialitiesList}
          onChange={handleSelectSpecialitiesChange}
          onBlur={(event) => {
            setBlurCheck({ ...BlurCheck, SpecialitiesList: true });
            validate();
          }}
        >
          {SpecialitiesResponse?.map((item, i) => (
            <Option key={item} value={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <Spin spinning={Loading} indicator={antIcon} />
        {/* <Icon className="PLUS" type="plus-circle" theme="filled" onClick={handleAddText} /> */}
      </div>
      {BlurCheck["SpecialitiesList"] && errors.SpecialitiesList && (
        <p className="ERROR">
          {BlurCheck["SpecialitiesList"] && errors.SpecialitiesList}
        </p>
      )}


      <div className="MyInformation-Body-Details">
        <h4><span className="star">*</span>Degrees</h4>
        <h4>:</h4>
        <div className="Textarea-Details">
          <TextArea
            placeholder="Enter your Degree"
            autoSize={{ minRows: 2 }}
            maxLength="120"
            name="degree"
            value={degree}
            onChange={handletextChange}
            onBlur={(event) => {
              console.log("object");
              setBlurCheck({ ...BlurCheck, [event.target.name]: true });
              validate();
            }}
          />
        </div>
        {/* <Icon className="PLUS" type="plus-circle" theme="filled" /> */}
      </div>
      {BlurCheck["degree"] && errors.degree && (
        <p className="ERROR">{BlurCheck["degree"] && errors.degree}</p>
      )}

      <div className="MyInformation-Body-Details">
        <h4><span className="star">*</span>Languages</h4>
        <h4>:</h4>
        <Select
          placeholder="Select your Language"
          mode="multiple"
          onChange={handleSelectChange}
          onBlur={(event) => {
            console.log("object");
            setBlurCheck({ ...BlurCheck, languages: true });
            validate();
          }}
        >
          <Option value="English">English</Option>
        </Select>
      </div>
      {BlurCheck["languages"] && errors.languages && (
        <p className="ERROR">{BlurCheck["languages"] && errors.languages}</p>
      )}

      <div className="MyInformation-Body-Details">
        <h4><span className="star">*</span>Practice license</h4>
        <h4>:</h4>
        <CustomInput
          className="InputCustom"
          placeholder="Enter your license"
          onBlur={(event) => {
            console.log("object");
            setBlurCheck({ ...BlurCheck, lincense: true });
            validate();
          }}
          value={lincense.substring(lincense?.lastIndexOf("/") + 1)}
          suffix={
            !spin?.lincense ? (
              <CustomUpload
                setUploadstate={setInformation}
                setspin={setspin}
                accept={
                  ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
                uploadfrom={"mdloginprocess"}
                uploadkey={"lincense"}
              // email={email}
              />
            ) : (
              <Spin className="SpinContent" spinning={spin?.lincense} />
            )
          }
        />
      </div>
      {BlurCheck["lincense"] && errors.lincense && (
        <p className="ERROR">{BlurCheck["lincense"] && errors.lincense}</p>
      )}

      <div className="MyInformation-Body-Details">
        <h4><span className="star">*</span>Profile Header</h4>
        <h4>:</h4>
        <div className="Textarea-Details">
          <TextArea
            placeholder="Enter your Profile Header"
            autoSize={{ minRows: 2 }}
            maxLength="120"
            name="profileHeader"
            value={profileHeader}
            onChange={handletextChange}
            onBlur={(event) => {
              console.log("object");
              setBlurCheck({ ...BlurCheck, [event.target.name]: true });
              validate();
            }}
          />
        </div>
        {/* <Icon className="PLUS" type="plus-circle" theme="filled" /> */}
      </div>
      {BlurCheck["profileHeader"] && errors.profileHeader && (
        <p className="ERROR">
          {BlurCheck["profileHeader"] && errors.profileHeader}
        </p>
      )}

      <div className="MyInformation-Body-Details">
        <h4>Bio/Summary</h4>
        <h4>:</h4>
        <TextArea
          rows={4}
          maxLength="240"
          value={summery}
          name="summery"
          onChange={handletextChange}
          onBlur={(event) => {
            console.log("object");
            setBlurCheck({ ...BlurCheck, [event.target.name]: true });
            validate();
          }}
        />
      </div>
      {BlurCheck["summery"] && errors.summery && (
        <p className="ERROR">{BlurCheck["summery"] && errors.summery}</p>
      )}

      <div className="NextBTN">
        <img
          src={require("../../../../Assets/Arrowbutton.svg")}
          alt="btn1"
          style={{ opacity: enable ? 1 : 0.5 }}
          onClick={handleGotoEducation}
        />
      </div>
    </div>
  );
};

export default MyInformation;
