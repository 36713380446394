import React, { useState, useEffect } from "react";
import { Icon, Row, Button, Col, Card, Table, Modal, Tooltip } from "antd";
import moment from "moment";
import "./MainDashBoard.scss";
import DashboardLayout from "./../DashboardLayout";
import { useHistory, Link } from "react-router-dom";
import DashboardSideContent from "./DashboardSideContent/DashboardSideContent";
import { useDispatch, useSelector } from "react-redux";
import {
  Appointments_fetch_Action,
  AppointmentsCount_fetch_Action,
  Todays_Appointments_fetch_Action,
  Consultation_fetch_Action,
} from "../../../Redux/Appointments/AppointmentsActions";
import AppointmentOverview from "./Components/AppointmentOverview";
import ConsultationOverview from "./Components/ConsultationOverview";
import CancelledOverview from "./Components/CancelledOverview";
import PendingOverview from "./Components/PendingOverview";
import UpcomingOverView from "./Components/UpcomingOverView";
import MedicationTable from "./Components/MedicationTable";
import AppointmentDetails from "./../DashboardAppointments/Components/AppointmentDetails";
import { Profile_fetch_Action } from "./../../../Redux/Profile/ProfileAction";
import GetlocalStorage from "../../../Common/localStorageHelper/getLocalStorage";

const MainDashBoard = () => {
  const [Loader, setLoader] = useState(false);
  const [morningAppointments, setMorningAppointments] = useState([]);
  const [afternoonAppointments, setAfternoonAppointments] = useState([]);
  const [evngAppointments, setEvngAppointments] = useState([]);
  const [MedicationModal, setMedicationModal] = useState(false);
  const [InfoModalVisible, setInfoModalVisible] = useState(false);
  const [AppointmentsInfo, setAppointmentsInfo] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  let getlocalStorage = GetlocalStorage();
  let Doctorid = getlocalStorage?.Doctorid;
  /*Consultations query params */
  let cosultationDate = moment().format("MM/DD/YYYY HH:mm");
  let chr = cosultationDate.slice(10, 13);
  let cmin = cosultationDate.slice(14, 16);

  useEffect(() => {
    dispatch(
      Todays_Appointments_fetch_Action(
        Doctorid,
        "Upcoming",
        routercall,
        handleLoader,
        chr, cmin
      )
    );
    dispatch(
      Consultation_fetch_Action(Doctorid, routercall, handleLoader, chr, cmin)
    );
    dispatch(
      Appointments_fetch_Action(
        Doctorid,
        "Upcoming",
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
    dispatch(
      AppointmentsCount_fetch_Action(
        Doctorid,
        routercall,
        handleLoader,
        chr.trim(),
        cmin
      )
    );
  }, [chr, cmin]);

  const ProfileData = useSelector((state) => state.ProfileReducer);
  useEffect(() => {
    dispatch(Profile_fetch_Action(Doctorid, routercall, handleLoader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routercall = (val) => {
    // history.push(val);
  };

  const handleLoader = (val) => {
    setLoader(val);
  };

  const AppointmentsData = useSelector((state) => state.AppointmentsReducer.data?.["Upcoming"]);

  const TodaysAppointmentsData = useSelector((state) => state.TodaysAppointmentsReducer.data?.TodayUpcomingRecord);

  const AppiontmentsCount = useSelector((state) => state.AppointmentsCountReducer.data);

  const consultationsData = useSelector((state) => state.ConsultationsReducer.data);
  console.log("getconsultation", consultationsData)



  useEffect(() => {
    bookings();
  }, [TodaysAppointmentsData]);

  const bookings = () => {
    if (TodaysAppointmentsData && TodaysAppointmentsData.length > 0) {
      TodaysAppointmentsData.map((item, index) => {
        getBookedSlots(item.appointmentSlotTime, item.slotSessionTime);
      });
    } else {
      setMorningAppointments([]);
      setAfternoonAppointments([]);
      setEvngAppointments([]);
    }
  };

  const getModeDisplay = (mode) => {
    switch (mode) {
      case 'TELEHEALTH': return 'Tele Health';
      case 'HOMEVISIT': return 'Home Visit';
      case 'CLINICALVISIT': return 'Clinic Visit';
      default: return 'Tele health';
    }
  };

  var morningSlots2 = [],
    evngSlots1 = [],
    afternoonSlots1 = [];

  const getBookedSlots = (appointmentSlotTime, slotSessionTime) => {
    var startTime1 = moment(appointmentSlotTime, "HH:mm");
    var morning = moment("12", "HH:mm");
    var afternoon = moment("17", "HH:mm");
    if (
      moment(morning).format("HH:mm") >= new moment(startTime1).format("HH:mm")
    ) {
      morningSlots2.push(
        new moment(startTime1).format("HH:mm") +
        "-" +
        new moment(startTime1.add(Number(slotSessionTime), "minutes")).format(
          "HH:mm"
        )
      );
    } else if (
      moment(afternoon).format("HH:mm") > moment(startTime1).format("HH:mm")
    ) {
      afternoonSlots1.push(
        moment(startTime1).format("HH:mm") +
        "-" +
        new moment(startTime1.add(Number(slotSessionTime), "minutes")).format(
          "HH:mm"
        )
      );
    } else if (
      moment(afternoon).format("HH:mm") <= moment(startTime1).format("HH:mm")
    ) {
      evngSlots1.push(
        new moment(startTime1).format("HH:mm") +
        "-" +
        new moment(startTime1.add(Number(slotSessionTime), "minutes")).format(
          "HH:mm"
        )
      );
    }
    setMorningAppointments(morningSlots2);
    setAfternoonAppointments(afternoonSlots1);
    setEvngAppointments(evngSlots1);
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "Patient_Name",
      key: "Patient_Name",
      // render: (text, record) => (
      //   <Tooltip overlayClassName="DashboardHealthRecord" title={text}>
      //     <span>
      //       {text?.slice(0, 10)} {text?.length >= 11 && "..."}{" "}
      //     </span>
      //   </Tooltip>
      // ),
    },
    { title: "Booking Id", dataIndex: "Booking_Id", key: "Booking_Id" },
    { title: "Issue", dataIndex: "Issue", key: "Issue" },
    { title: "Date", dataIndex: "Date", key: "Date" },
    { title: "Slot Timing", dataIndex: "Slot_Timing", key: "Slot_Timing" },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => (
        <h4>Accepted</h4>
      ),
    },
    {
      title: "",
      dataIndex: "info",
      render: (text, record) => (
        <Icon
          className="InfoIcon"
          onClick={() => onclick_AppointmentsInfo(record.Info)}
          type="info-circle"
        />
      ),
    },
  ];

  const UpcomingData = [];

  AppointmentsData &&
    AppointmentsData.map((item, index) => {
      const { slotSessionTime, appointmentSlotTime } = item;
      var startTime = moment(appointmentSlotTime, "HH:mm");
      UpcomingData.push({
        Patient_Name: item.patientName,
        Booking_Id: (
          <Tooltip
            overlayClassName="issuesAppoinmentData"
            title={item.bookingId}
          >
            <span>{item.bookingId.slice(0, 10)}...</span>
          </Tooltip>
          // item.bookingId?.substring(0, 11)
        ),
        Issue: (<span>{item.purposeOfVist}</span>),
        Date: moment(item.appointmentDate).format("MMM-DD-YYYY"),
        Slot_Timing: `${appointmentSlotTime}- ${moment(
          startTime.add(Number(slotSessionTime), "minutes")
        ).format("HH:mm")}`,
        Info: item,
      });
    });

  const onclick_AppointmentsInfo = (val) => {
    setAppointmentsInfo(val);
    setInfoModalVisible(true);
  };

  const getNearestAppointment = (appointments) => {
    const now = new Date();

    // Combine appointmentDate and appointmentSlotTime into a single Date object
    const upcomingAppointments = appointments?.map((appointment) => {
      const appointmentDate = new Date(appointment?.appointmentDate);
      const [hours, minutes] = appointment?.appointmentSlotTime.split(':');

      appointmentDate.setHours(hours, minutes);

      return {
        ...appointment,
        appointmentFullDate: appointmentDate // Combine date and time
      };
    });

    // Filter for future appointments only
    const futureAppointments = upcomingAppointments?.filter(
      (appointment) => appointment.appointmentFullDate > now
    );

    // Find the nearest appointment in the future
    if (futureAppointments?.length === 0) return null; // No future appointments

    const nearestAppointment = futureAppointments?.reduce((prev, curr) => {
      return curr.appointmentFullDate < prev.appointmentFullDate ? curr : prev;
    });

    return nearestAppointment;
  };

  // Example usage:
  const nearestAppointment = getNearestAppointment(consultationsData);

  console.log("jxhcygguydgffss", consultationsData)



  return (
    <div>
      <DashboardLayout>
        <div className="MainDashboard-Body">
          <Row>
            <Col lg={18}>
              <div className="MainDashboard-Slot-Timings">
                <div className="Slot-Timings-Today">
                  <h3>Your Slot timings for today :</h3>
                  <p>{moment().format("MMM-DD-YYYY, h:mm a")}</p>
                </div>
                {morningAppointments?.length === 0 &&
                  afternoonAppointments?.length === 0 &&
                  evngAppointments?.length === 0 && (
                    <div className="Slot-Timings-NoContent">
                      <h5>No slots Available</h5>
                    </div>
                  )}
                <div className="Slot-Timings-Today-Body">
                  {morningAppointments?.length !== 0 && (
                    <div className="Slot-Timings-Today-Content">
                      <div className="SlotIMG">
                        <img
                          src={require("../../../Assets/morning1.png")}
                          alt="icon1"
                        />
                      </div>
                      <div className="Slot-Timings">
                        {morningAppointments?.sort()?.map((i) => (
                          <Button className="SlotBtn" key={i}>{i}</Button>
                        ))}
                      </div>
                    </div>
                  )}
                  {afternoonAppointments?.length !== 0 && (
                    <div className="Slot-Timings-Today-Content">
                      <div className="SlotIMG">
                        <img
                          src={require("../../../Assets/afternoon1.png")}
                          alt="icon1"
                        />
                      </div>
                      <div className="Slot-Timings">
                        {afternoonAppointments?.sort()?.map((i) => (
                          <Button className="SlotBtn" key={i}>{i}</Button>
                        ))}
                      </div>
                    </div>
                  )}
                  {evngAppointments?.length !== 0 && (
                    <div className="Slot-Timings-Today-Content2">
                      <div className="SlotIMG">
                        <img
                          src={require("../../../Assets/evening1.png")}
                          alt="icon1"
                        />
                      </div>
                      <div className="Slot-Timings">
                        {evngAppointments?.sort()?.map((i) => (
                          <Button className="SlotBtn" key={i}>{i}</Button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="MainDashboard-Body-Overview">
                <h3>Overview</h3>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> */}
                <div className="MainDashboard-Body-Overview-Content">
                  <AppointmentOverview />
                  <ConsultationOverview />
                  <CancelledOverview />
                  <PendingOverview />
                  <UpcomingOverView />
                </div>
              </div>

              {/* {consultationsData && consultationsData.length !== 0 && (
                <div className="MainDashboard-ScheduledAppointment">
                  <h3> Scheduled Appointment</h3>
                  <div className="ScheduledAppointment-Content">
                    <div className="Appointment-Details">
                      <div className="DETAILS">
                        <h4>Name</h4> <span>:</span>
                        <h4>
                          {consultationsData &&
                            consultationsData.length !== 0 ? (
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                            //title={consultationsData[0].patientName}
                            >
                              <span className="appoinmentSpan">
                                {consultationsData[0]?.patientName}
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h4>Mode</h4> <span>:</span>
                        <h4>
                          {consultationsData && consultationsData.length !== 0
                            ? consultationsData[0]?.servicesName
                            : "Tele Health"}
                        </h4>
                      </div>
                    </div>
                    <div className="Appointment-Details">
                      <div className="DETAILS">
                        <h4>Issue</h4> <span>:</span>
                        <h4>
                          {consultationsData && consultationsData.length !== 0
                            ? `${consultationsData[0]?.purposeOfVist.substring(
                              0,
                              6
                            )}
                              ${consultationsData[0]?.purposeOfVist.length > 6
                              ? "..."
                              : ""
                            }`
                            : ""}
                          &nbsp;&nbsp;
                          <Tooltip title={consultationsData[0]?.purposeOfVist}>
                            <Icon type="info-circle" />
                          </Tooltip>
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h4>Slot</h4> <span>:</span>
                        <h4>
                          {consultationsData && consultationsData.length !== 0
                            ? consultationsData[4]?.appointmentSlot
                            : ""} {" "}
                          ({moment(consultationsData[0]?.appointmentDate).format("MMM-DD-YYYY")})
                        </h4>
                      </div>
                    </div>
                    <div className="Appointment-Details1">
                      <div className="DETAILS">
                        <h4>Token Id</h4> <span>:</span>
                        <h4 style={{ overflowWrap: "break-word" }}>
                          {consultationsData &&
                            consultationsData.length !== 0 ? (

                            <span className="appoinmentSpan">
                              {consultationsData[0]?.tokenId}
                            </span>

                          ) : (
                            "19J216"
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {consultationsData &&
                    consultationsData.length !== 0 &&
                    (consultationsData[0]?.servicesName === "TELEHEALTH" ? (
                      <Card className="Appointment-MeetingCard"
                      >
                        <h4>Reminder meeting :</h4>
                        <h4>
                          Consultation
                          <span>{consultationsData && consultationsData.length !== 0
                            ? consultationsData[0].appointmentSlotTime
                            : ""}
                          </span>
                        </h4>
                        <div className="Meetingcard-Btns">
                          <Link
                            to={`/videoCall/${getlocalStorage?.fullName}/${consultationsData && consultationsData[0]
                              ? consultationsData[0].tokenId
                              : "19J216"
                              }`}
                            target="_blank"
                          >
                            <Button className="startBtn" type="primary">
                              Start
                            </Button>
                          </Link>
                          <Button
                            className="medicationBtn"
                            type="primary"
                            onClick={() => setMedicationModal(true)}
                          >
                            Medication
                          </Button>
                        </div>
                      </Card>
                    ) : (
                      <div
                        className="Appointment-MeetingCard"
                        style={{
                          backgroundColor: "unset",
                          top: "38%",
                          right: "4%",
                          border: "unset",
                        }}
                      >
                        <img
                          className="ScheduledAppointment-Location"
                          src={require("../../../Assets/Location.svg")}
                        />
                      </div>
                    ))}
                </div>
              )} */}

              {nearestAppointment &&
                <div className="MainDashboard-ScheduledAppointment">
                  <h3> Scheduled Appointment</h3>
                  <div className="ScheduledAppointment-Content">
                    <div className="Appointment-Details">
                      <div className="DETAILS">
                        <h4>Name</h4> <span>:</span>
                        <h4>
                          {nearestAppointment?.patientName}
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h4>Mode</h4> <span>:</span>
                        <h4>{getModeDisplay(nearestAppointment?.servicesName)}</h4>
                      </div>
                    </div>
                    <div className="Appointment-Details">
                      <div className="DETAILS">
                        <h4>Issue</h4> <span>:</span>
                        <h4>
                          {nearestAppointment?.purposeOfVist?.length > 10 ?
                            <span>
                              {nearestAppointment?.purposeOfVist?.slice(0, 10)}
                              <Tooltip title={nearestAppointment?.purposeOfVist}>
                                <Icon type="info-circle" />
                              </Tooltip>
                            </span> :
                            <spn>{nearestAppointment?.purposeOfVist}</spn>
                          }
                        </h4>
                      </div>
                      <div className="DETAILS">
                        <h4>Slot</h4> <span>:</span>
                        <h4>
                          {moment(nearestAppointment?.appointmentDate).format("MMM-DD-YYYY")}
                        </h4>
                      </div>
                    </div>
                    <div className="Appointment-Details1">
                      <div className="DETAILS">
                        <h4>Token Id</h4> <span>:</span>
                        <h4 style={{ overflowWrap: "break-word" }}>
                          {nearestAppointment?.tokenId ? nearestAppointment?.tokenId : "19J216"}
                        </h4>
                      </div>
                    </div>
                  </div>

                  {nearestAppointment?.servicesName === "TELEHEALTH" ? (
                    <Card className="Appointment-MeetingCard">
                      <h4>Reminder meeting :</h4>
                      <h4>
                        Consultation
                        <span>
                          {nearestAppointment?.appointmentSlotTime}
                        </span>
                      </h4>
                      <div className="Meetingcard-Btns">
                        <Link
                          to={`/videoCall/${getlocalStorage?.fullName}/${nearestAppointment?.tokenId
                            ? nearestAppointment.tokenId
                            : "19J216"
                            }`}
                          target="_blank"
                        >
                          <Button className="startBtn" type="primary">
                            Start
                          </Button>
                        </Link>
                        {/* <Button
                          className="medicationBtn"
                          type="primary"
                          onClick={() => setMedicationModal(true)}
                        >
                          Medication
                        </Button> */}
                      </div>
                    </Card>
                  ) : (
                    <div
                      className="Appointment-MeetingCard"
                      style={{
                        backgroundColor: "unset",
                        top: "38%",
                        right: "4%",
                        border: "unset",
                      }}
                    >
                      <img
                        className="ScheduledAppointment-Location"
                        src={require("../../../Assets/Location.svg")}
                      />
                    </div>
                  )}
                </div>
              }

              <div className="MainDashboard-UpcomingAppointments">
                <h3>Upcoming Appointments</h3>
                <div className="UpcomingAppointments-Table">
                  <Table
                    columns={columns}
                    dataSource={UpcomingData}
                    pagination={{ pageSize: 6, size: "small" }}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <DashboardSideContent />
            </Col>
          </Row>
        </div>
      </DashboardLayout>

      {/* <Modal className="StatusModal" centered footer={null}
        visible={modalVisible} closable={false} onCancel={() => setmodalVisible(false)}
      >
       <StatusModal bookingRecord={bookingRecord} setmodalVisible={setmodalVisible} 
          setRoomNumber={setRoomNumber} aryDates={aryDates} rooms={rooms}
       />
      </Modal> */}

      <Modal
        className="Medication-Modal"
        visible={MedicationModal}
        centered={true}
        footer={null}
        onCancel={() => setMedicationModal(false)}
        closable={false}
      >
        <Icon
          onClick={() => setMedicationModal(false)}
          className="CloseIcon2"
          type="close-circle"
          theme="filled"
        />
        <MedicationTable
          Appointmentsid={consultationsData && consultationsData[0]}
          setMedicationModal={setMedicationModal}
        />
      </Modal>

      <Modal
        className="Appointments-InfoModal"
        visible={InfoModalVisible}
        centered={true}
        footer={null}
        onCancel={() => setInfoModalVisible(false)}
        closable={false}
      >
        <Icon
          onClick={() => setInfoModalVisible(false)}
          className="IconClose"
          type="close-circle"
          theme="filled"
        />
        <AppointmentDetails AppointmentInfo={AppointmentsInfo} />
      </Modal>
    </div>
  );
};
export default MainDashBoard;
